import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar as BootstrapNavbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faSignOutAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import '../features/Navbar.css';

const Navbar = ({ userData, handleLogout }) => {

  return (
    <BootstrapNavbar variant="dark" className="navbar">
      <Nav className="mr-auto">
        <Nav.Link as={NavLink} to="/create-staff" className="nav-link">
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
          Create Staff
        </Nav.Link>
        <Nav.Link as={NavLink} to="/create-customer" className="nav-link">
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
          Create Customer
        </Nav.Link>
        <Nav.Link as={NavLink} to="/create" className="nav-link">
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
          Create Service
        </Nav.Link>
        <Nav.Link as={NavLink} to="/create-quotation" className="nav-link">
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
          Create Quotation
        </Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link as={NavLink} to="/profile" className="nav-link">
          <FontAwesomeIcon icon={faUserCircle} style={{ fontSize: "25px", marginRight: "5px" }} />
          {userData.firstName} {/* Display user's first name */}
        </Nav.Link>
        <Nav.Link onClick={handleLogout} className="nav-link" style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
          Logout
        </Nav.Link>
      </Nav>
    </BootstrapNavbar>
  );
};

export default Navbar;