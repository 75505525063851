import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../config';
import './Profile.css';
import { useNavigate } from 'react-router-dom';

const Profile = ({ userData }) => {
  // Separate state for profile data
  const [profileData, setProfileData] = useState({
    firstName: userData.firstName || '',
    lastName: userData.lastName || '',
    email: userData.email || '',
    nic: userData.nic || '',
    contactNo: userData.contactNo || '',
    username: userData.username || '',
    password: userData.password || ''
  });

  const navigate = useNavigate();

  // Update profile data when userData changes
  useEffect(() => {
    setProfileData({
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      email: userData.email || '',
      nic: userData.nic || '',
      contactNo: userData.contactNo || '',
      username: userData.username || '',
    password: userData.password || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/staff/update/${userData.staff_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const updatedUser = await response.json();
      console.log('Profile updated successfully:', updatedUser);
      toast.success('Profile updated successfully');
      // Optionally update userData state or handle any additional actions after update

      // Username and password remain unchanged
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  return (
    <Container fluid className="profile">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png' style={{width: "20%", marginLeft: "340px", borderRadius: "25px", marginBottom: "25px"}} alt='profile-image'/>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="firstName">
              <Form.Label className="form-label">First Name:</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={profileData.firstName}
                onChange={handleChange}
                required
                disabled
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="lastName">
              <Form.Label className="form-label">Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={profileData.lastName}
                onChange={handleChange}
                required
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label className="form-label">Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={profileData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="nic">
              <Form.Label className="form-label">NIC:</Form.Label>
              <Form.Control
                type="text"
                name="nic"
                value={profileData.nic}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="contactNo">
              <Form.Label className="form-label">Contact No:</Form.Label>
              <Form.Control
                type="text"
                name="contactNo"
                value={profileData.contactNo}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
            <Button variant="primary" type="submit">
              Update Profile
            </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default Profile;