import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Container, Card, Table, Spinner } from 'react-bootstrap';
import './ViewQuotation.css';
import BASE_URL from '../config';

const ViewQuotation = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceAndCustomer = async () => {
      try {
        const serviceResponse = await fetch(`${BASE_URL}/quotation/${id}`);
        if (!serviceResponse.ok) {
          throw new Error('Failed to fetch service');
        }
        const serviceData = await serviceResponse.json();
        setService(serviceData);

        if (serviceData && serviceData.customer_id) {
          const customerResponse = await fetch(`${BASE_URL}/customers/${serviceData.customer_id}`);
          if (!customerResponse.ok) {
            throw new Error('Failed to fetch customer');
          }
          const customerData = await customerResponse.json();
          setCustomer(customerData.data);
        } else {
          throw new Error('Customer ID not found in service data');
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setError(error.message);
      }
    };

    fetchServiceAndCustomer();
  }, [id]);

  const calculateTotalPartsAmount = () => {
    return service?.tasks?.reduce((total, task) => total + task.amount, 0) || 0;
  };

  const calculateTotalLaborsAmount = () => {
    return service?.extraTasks?.reduce((total, extraTask) => total + extraTask.extraAmount, 0) || 0;
  };

  return (
    <Container className="view-service-container d-flex flex-column align-items-center">
      <h2 className="page-title text-center">Quotation Details</h2>
      {error ? (
        <p className="error-message text-center">{error}</p>
      ) : service && customer ? (
        <Row className="w-100 justify-content-center">
          <Col md={6}>
            <Card className="details-card mb-4">
              <Card.Body>
                <Card.Title>Quotation Information</Card.Title>
                <hr />
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Quotation ID</strong></td>
                      <td>{service.quotation_id}</td>
                    </tr>
                    <tr>
                      <td><strong>Vehicle Number</strong></td>
                      <td>{service.vehicle_no}</td>
                    </tr>
                    <tr>
                      <td><strong>Payment Type</strong></td>
                      <td>{service.paymentType}</td>
                    </tr>
                    <tr>
                      <td><strong>ODO Meter</strong></td>
                      <td>{service.odoMeter}</td>
                    </tr>
                    <tr>
                      <td colSpan="2" style={{textAlign: "center"}}>{service.desc}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table>
                  <thead>
                    <tr>
                      <th>Vehicle Parts</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {service.tasks && service.tasks.map((task, index) => (
                      <tr key={index}>
                        <td>{task.task}</td>
                        <td>{task.unit}</td>
                        <td>{`${task.amount}/${task.unit}`}</td>
                        <td>Rs. {task.amount}.00</td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Total Vehicle Parts Amount</strong></td>
                      <td colSpan="2">Rs. {calculateTotalPartsAmount()}.00</td>
                    </tr>
                  </tbody>
                </Table>
                <Table>
                  <thead>
                    <tr>
                      <th>Labors</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {service.extraTasks && service.extraTasks.map((extraTask, index) => (
                      <tr key={index}>
                        <td>{extraTask.extraTask}</td>
                        <td>Rs. {extraTask.extraAmount}.00</td>
                      </tr>
                    ))}
                    <tr>
                      <td><strong>Total Labors Amount</strong></td>
                      <td>Rs. {calculateTotalLaborsAmount()}.00</td>
                    </tr>
                  </tbody>
                </Table>
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Total Amount</strong></td>
                      <td>Rs. {calculateTotalPartsAmount() + calculateTotalLaborsAmount()}.00</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="details-card mb-4">
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <hr />
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Customer ID</strong></td>
                      <td>{customer.customer_id}</td>
                    </tr>
                    <tr>
                      <td><strong>Title</strong></td>
                      <td>{customer.title}</td>
                    </tr>
                    <tr>
                      <td><strong>First Name</strong></td>
                      <td>{customer.firstName}</td>
                    </tr>
                    <tr>
                      <td><strong>Last Name</strong></td>
                      <td>{customer.lastName}</td>
                    </tr>
                    <tr>
                      <td><strong>Email</strong></td>
                      <td>{customer.email}</td>
                    </tr>
                    <tr>
                      <td><strong>NIC</strong></td>
                      <td>{customer.nic}</td>
                    </tr>
                    <tr>
                      <td><strong>Contact No</strong></td>
                      <td>{customer.contactNo}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </Container>
  );
};

export default ViewQuotation;