/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CreateService from './components/service/CreateService';
import UpdateService from './components/service/UpdateService';
import ViewService from './components/service/ViewService';
import Dashboard from './components/Dashboard';
import SidebarWithNavbar from './components/features/SidebarWithNavbar';
import ViewAllServices from './components/service/ViewAllServices';
import Signin from './components/authentication/Signin';
import LoadingScreen from './components/features/LoadingScreen';
import CreateCustomer from './components/customers/CreateCustomer';
import UpdateCustomer from './components/customers/UpdateCustomer';
import ViewCustomer from './components/customers/ViewCustomer';
import ViewAllCustomers from './components/customers/ViewAllCustomers';
import CreateStaff from './components/staff/CreateStaff';
import UpdateStaff from './components/staff/UpdateStaff';
import ViewStaff from './components/staff/ViewStaff';
import ViewAllStaff from './components/staff/ViewAllStaff';
import ViewAllPendingServices from './components/service/ViewAllPendingServices';
import Profile from './components/authentication/Profile';
import CreateQuotation from './components/quotation/CreateQuotation';
import UpdateQuotation from './components/quotation/UpdateQuotation';
import ViewQuotation from './components/quotation/ViewQuotation';
import ViewAllQuotations from './components/quotation/ViewAllQuotations';
import ViewAllServicesDetailsEachMonth from './components/service/viewAllServicesDetailsEachMonth';
import ViewAllInvoices from './components/service/ViewAllInvoices';
import ViewAllCustomersLM from './components/customers/ViewAllCustomersLM';
import ViewAllQuotationsLM from './components/quotation/ViewAllQuotationsLM';
import ViewAllStaffLM from './components/staff/ViewAllStaffLM';
import ViewAllServicesLM from './components/service/ViewAllServicesLM';

const AppRouter = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Timeout period in milliseconds (e.g., 30 minutes)
  const timeout = 30 * 60 * 1000; // 30 minutes

  // Function to set userData and reset timeout
  const handleUserData = (data) => {
    setUserData(data);
    resetTimeout();
  };

  // Function to clear session-related data
  const handleLogout = () => {
    localStorage.removeItem('userData'); // Clear stored user data
    // Optionally, clear other session data like tokens, etc.
    clearTimeout(timeoutId); // Clear timeout
    setUserData(null); // Clear userData state
    window.location.href = '/';
  };

  // Timeout ID reference
  let timeoutId;

  // Function to reset timeout
  const resetTimeout = () => {
    clearTimeout(timeoutId); // Clear previous timeout
    timeoutId = setTimeout(handleLogout, timeout); // Set new timeout
  };

  // Effect to reset timeout on user interaction
  useEffect(() => {
    const resetTimeoutOnUserAction = () => {
      resetTimeout();
    };

    // Add event listeners for user interactions
    window.addEventListener('click', resetTimeoutOnUserAction);
    window.addEventListener('keydown', resetTimeoutOnUserAction);

    // Clear timeout and remove event listeners on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('click', resetTimeoutOnUserAction);
      window.removeEventListener('keydown', resetTimeoutOnUserAction);
    };
  }, []);

  // Simulate initial loading for demonstration
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate 2 seconds loading time
  }, []);

  // Effect to fetch userData from localStorage on initial mount
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  // Effect to store userData in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  return (
    <Router>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="App">
          {userData ? (
            <SidebarWithNavbar userData={userData} handleLogout={handleLogout} />
          ) : (
            <Routes>
              <Route path="/" element={<Signin handleUserData={handleUserData} />} />
            </Routes>
          )}
          <div className={`content ${userData ? 'full-width' : ''}`}>
            <Routes>
              {userData && (
                <>
                  <Route path="/dashboard" element={<Dashboard userData={userData}/>} />
                  <Route path="/create" element={<CreateService userData={userData} />} />
                  <Route path="/update/:id" element={<UpdateService userData={userData} />} />
                  <Route path="/view/:id" element={<ViewService userData={userData} />} />
                  <Route path="/view-all-services" element={<ViewAllServices userData={userData} />} />
                  <Route path="/view-all-services-lm" element={<ViewAllServicesLM userData={userData} />} />
                  <Route path="/create-customer" element={<CreateCustomer userData={userData} />} />
                  <Route path="/update-customer/:id" element={<UpdateCustomer userData={userData} />} />
                  <Route path="/view-customer/:id" element={<ViewCustomer userData={userData} />} />
                  <Route path="/view-all-customers" element={<ViewAllCustomers userData={userData} />} />
                  <Route path="/view-all-customers-lm" element={<ViewAllCustomersLM userData={userData} />} />
                  <Route path="/create-staff" element={<CreateStaff userData={userData} />} />
                  <Route path="/update-staff/:id" element={<UpdateStaff userData={userData} />} />
                  <Route path="/view-staff/:id" element={<ViewStaff userData={userData} />} />
                  <Route path="/view-all-staff" element={<ViewAllStaff userData={userData} />} />
                  <Route path="/view-all-staff-lm" element={<ViewAllStaffLM userData={userData} />} />
                  <Route path="/view-all-pending-services" element={<ViewAllPendingServices userData={userData} />} />
                  <Route path="/profile" element={<Profile userData={userData} />} /> {/* Use element prop for rendering Profile component */}
                  <Route path="/create-quotation" element={<CreateQuotation userData={userData} />} />
                  <Route path="/update-quotation/:id" element={<UpdateQuotation userData={userData} />} />
                  <Route path="/view-quotation/:id" element={<ViewQuotation userData={userData} />} />
                  <Route path="/view-all-quotations" element={<ViewAllQuotations userData={userData} />} />
                  <Route path="/view-all-quotations-lm" element={<ViewAllQuotationsLM userData={userData} />} />
                  <Route path="/view-all-service-details" element={<ViewAllServicesDetailsEachMonth userData={userData} />} />
                  <Route path="/view-all-invoices" element={<ViewAllInvoices userData={userData} />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      )}
    </Router>
  );
};

export default AppRouter;