/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Row, Toast } from 'react-bootstrap'; // Import Toast from react-bootstrap
import logo from '../assets/logo512.png';
import './Signin.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../config';

function Signin({ handleUserData }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showToast, setShowToast] = useState(false); // State for showing toast
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/staff/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'Login failed');
      }

      const userData = await response.json();

      // Store userData in state of AppWrapper
      handleUserData(userData);

      // Redirect to dashboard or other appropriate route
      navigate('/dashboard');
    } catch (error) {
      toast.error('Invalid username or password');
      setShowToast(true); // Show toast for invalid credentials
    }
  };

  return (
    <div className="Signin">
      <div className="login-container">
        <Row>
          <Col md={6} className="text-center">
            <div className="logo-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
          </Col>
          <Col md={6}>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  placeholder="Username"
                  className="form-control"
                  required
                />
              </div>
              <br/>
              <div className="form-group">
                <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  className="form-control"
                  required
                />
              </div>
              <br/>
              <button className='signin-btn' type="submit">
                Sign in
              </button>
              {error && <p className="error-message">{error}</p>}
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Signin;