import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
    <ToastContainer autoClose={3000} closeOnClick={false} toastClassName="toast-container"/> {/* Auto-close after 3 seconds, disable close on click */}
  </React.StrictMode>,
  document.getElementById('root')
);