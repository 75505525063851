import React from 'react';
import { Form } from 'react-bootstrap';

const SearchServices = ({ searchTerm, setSearchTerm }) => {
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Form.Group controlId="search">
      <Form.Control
        type="text"
        placeholder="Search...."
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </Form.Group>
  );
};

export default SearchServices;