/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Spinner } from 'react-bootstrap';
import './UpdateStaff.css';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateStaff = ({userData}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch specific staff member data
    fetch(`${BASE_URL}/staff/${id}`)
      .then(response => response.json())
      .then(data => {
        setStaff(data); // Assuming the structure of your API response
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching staff:', error);
        setLoading(false); // Ensure loading state is handled in case of errors
      });
  }, [id]);

  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    nic: '',
    contactNo: '',
    username: '',
    password: '',
    staffId: userData.staff_id
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (staff) {
      setFormData({
        firstName: staff.firstName || '',
        lastName: staff.lastName || '',
        email: staff.email || '',
        nic: staff.nic || '',
        contactNo: staff.contactNo || '',
        username: staff.username || '',
        password: staff.password || '',
        staffId: userData.staff_id
      });
    }
  }, [staff]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/staff/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to update staff');
      }

      const updatedStaff = await response.json();
      console.log('Staff updated successfully:', updatedStaff);
      toast.success('Staff updated successfully'); // Display success toast message
      navigate('/view-all-staff'); // Navigate to view all staff page after successful update
    } catch (error) {
      console.error('Error updating staff:', error);
      toast.error('Failed to update staff. Please try again.'); // Display error toast message
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="update-staff">
      <h2>Update Staff</h2>
      <form onSubmit={handleSubmit}>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          NIC:
          <input
            type="text"
            name="nic"
            value={formData.nic}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Contact No:
          <input
            type="text"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Username:
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            disabled
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </label>
        <Row>
          <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">Update Staff</Button>
        </Row>
      </form>
    </div>
  );
};

export default UpdateStaff;