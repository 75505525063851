import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../config';
import './UpdateCustomer.css';

const UpdateCustomer = ({ userData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch customer details from your API endpoint
    fetch(`${BASE_URL}/customers/${id}`)
      .then(response => response.json())
      .then(data => {
        setCustomer(data.data); // Assuming the structure of your API response
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch(error => {
        console.error('Error fetching customer:', error);
        setLoading(false); // Ensure loading state is handled in case of errors
      });
  }, [id]);

  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    nic: '',
    contactNo: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (customer) {
      setFormData({
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        email: customer.email || '',
        title: customer.title || '',
        nic: customer.nic || '',
        contactNo: customer.contactNo || '',
      });
    }
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/customers/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          staffId: userData.staff_id // Include staffId in the request payload
        }),
      });
      const updatedCustomer = await response.json();
      console.log('Customer updated successfully:', updatedCustomer);
      toast.success('Customer updated successfully');
      navigate('/view-all-customers'); // Navigate to view all customers page after successful update
    } catch (error) {
      console.error('Error updating customer:', error);
      toast.error('Failed to update customer. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="update-customer">
      <h2>Update Customer</h2>
      <form onSubmit={handleSubmit}>
        <label>Title
          <select
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          >
            <option value="">Select Title</option>
            <option value="Mr.">Mr.</option>
      <option value="Ms.">Ms.</option>
      <option value="Mrs.">Mrs.</option>
      <option value="Dr.">Dr.</option>
      <option value="Prof.">Prof.</option>
      <option value=" ">None</option>
          </select>
        </label>
        <br />
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          NIC:
          <input
            type="text"
            name="nic"
            value={formData.nic}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Contact No:
          <input
            type="text"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
            required
          />
        </label>
        <Row>
          <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">Update Customer</Button>
        </Row>
      </form>
    </div>
  );
};

export default UpdateCustomer;