import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row, Col, Form } from 'react-bootstrap';
import './ViewAllPendingServices.css';
import axios from 'axios';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationComponent from '../features/Pagination';
import { FaPrint, FaEdit, FaEye, FaCheckCircle, FaTrashAlt, FaBan } from 'react-icons/fa';
import Invoice from './Invoice';
import ExtraInvoice from './ExtraInvoice';
import { TbFileInvoice } from "react-icons/tb";

const ViewAllPendingServices = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage] = useState(5);
  const [selectedService, setSelectedService] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await fetch(`${BASE_URL}/service`);
      if (!response.ok) {
        throw new Error('Failed to fetch services');
      }
      const servicesData = await response.json();

      // Filter services with pending status
      const pendingServices = servicesData.filter(service => service.service_status === 'Pending');

      // Fetch customer names for each pending service
      const servicesWithCustomers = await Promise.all(
        pendingServices.map(async (service) => {
          try {
            if (service.customer_id) {
              const customerResponse = await fetch(`${BASE_URL}/customers/${service.customer_id}`);
              if (!customerResponse.ok) {
                throw new Error(`Failed to fetch customer for customer ID ${service.customer_id}`);
              }
              const customerData = await customerResponse.json();

              if (customerData.data && customerData.data.firstName && customerData.data.lastName) {
                return {
                  ...service,
                  customerName: `${customerData.data.firstName} ${customerData.data.lastName}`
                };
              } else {
                throw new Error(`Invalid customer data for customer ID ${service.customer_id}`);
              }
            } else {
              throw new Error(`Customer ID not found for service ID ${service._id}`);
            }
          } catch (error) {
            console.error('Error fetching customer data:', error);
            return {
              ...service,
              customerName: 'Unknown'
            };
          }
        })
      );

      setServices(servicesWithCustomers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;

  // Adjusted filtering based on searchTerm
  const filteredServices = services.filter(service =>
    service.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.vehicle_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
    service.desc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);

  const toggleStatus = async (id) => {
    try {
      const serviceToUpdate = services.find(service => service.service_id === id);
      const updatedStatus = serviceToUpdate.service_status === 'Completed' ? 'Pending' : 'Completed';

      const response = await axios.put(`${BASE_URL}/service/update_service_status/${id}`, {
        service_status: updatedStatus
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        const updatedServices = services.map(service =>
          service.service_id === id ? { ...service, service_status: updatedStatus } : service
        );
        setServices(updatedServices);
        toast.success(`Service status updated to ${updatedStatus}`);
      } else {
        throw new Error('Failed to update service status');
      }
    } catch (error) {
      console.error('Error toggling service status:', error);
      toast.error('Failed to update service status. Please try again.');
    }
  };

  const generateInvoice = (id, type) => {
    const service = services.find(service => service.service_id === id);
    if (service) {
      setSelectedService(service);
      setInvoiceType(type);
      setShowInvoiceModal(true);
    }
  };

  const handleCloseInvoice = () => {
    setSelectedService(null);
    setShowInvoiceModal(false);
    setInvoiceType('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/service/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedServices = services.filter(service => service.service_id !== id);
        setServices(updatedServices);
        toast.success('Service deleted successfully');
      } else {
        throw new Error('Failed to delete service');
      }
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service. Please try again.');
    }
  };

  return (
    <div className="view-all-services">
      <h2 className="text-center">All Pending Services</h2>

      <Form.Group controlId="search">
        <Form.Control
          type="text"
          placeholder="Search...."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form.Group>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Service ID</th>
            <th>Type</th>
            <th>Vehicle Number</th>
            <th>Service Status</th>
            <th>Created Date</th>
            <th>Total Amount</th>
            <th>Customer Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {currentServices.map(service => (
            <tr key={service.service_id}>
              <td>{service.service_id}</td>
              <td>{service.type}</td>
              <td>{service.vehicle_no}</td>
              <td>{service.service_status}</td>
              <td>{new Date(service.createdAtService).toLocaleString()}</td>
              <td>Rs. {service.totalAmount}.00</td>
              <td>{service.customerName}</td>
              <td>
                <Link to={`/update/${service.service_id}`} className="action-button">
                  <Button variant="warning" className="mr-2"><FaEdit /></Button>
                </Link>
                <Link to={`/view/${service.service_id}`} className="action-button">
                  <Button variant="primary" className="mr-2"><FaEye /></Button>
                </Link>
                {service.service_status === 'Completed' && (
                  <>
                    <Button
                      variant="success"
                      onClick={() => generateInvoice(service.service_id, 'invoice')}
                      className="action-button"
                    >
                      <FaPrint className="mr-2" />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => generateInvoice(service.service_id, 'extraInvoice')}
                      className="action-button"
                    >
                      <TbFileInvoice className="mr-2" />
                    </Button>
                  </>
                )}
                <Button
                  variant="info"
                  onClick={() => toggleStatus(service.service_id)}
                  className="action-button"
                >
                  {service.service_status === 'Pending' ? (
                    <FaCheckCircle style={{ color: 'green' }} />
                  ) : (
                    <FaBan style={{ color: 'red' }} />
                  )}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(service.service_id)}
                  className="action-button"
                >
                  <FaTrashAlt />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Row className="justify-content-center">
        <Col xs="auto">
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(filteredServices.length / servicesPerPage)}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>

      <Invoice
        show={showInvoiceModal && invoiceType === 'invoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
      <ExtraInvoice
        show={showInvoiceModal && invoiceType === 'extraInvoice'}
        handleClose={handleCloseInvoice}
        service={selectedService}
      />
    </div>
  );
};

export default ViewAllPendingServices;