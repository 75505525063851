// SidebarWithNavbar.jsx
import React from 'react';
import Sidebar from './Sidebar'; // Example sidebar component
import Navbar from './Navbar'; // Your Navbar component

const SidebarWithNavbar = ({ userData, handleLogout }) => {
  return (
    <div className="sidebar">
      <Sidebar />
      <Navbar userData={userData} handleLogout={handleLogout} />
    </div>
  );
};

export default SidebarWithNavbar;