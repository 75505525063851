import React, { useRef } from 'react';
import { Button, Modal, Row, Col, Table } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Invoice.css';
import logo from '../assets/logo512.png';

const ExtraInvoice = ({ show, handleClose, service }) => {
  const printRef = useRef();

  const handlePrint = () => {
    const printContents = printRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownload = () => {
    const element = printRef.current;
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Invoice_${service.service_id}_${service.vehicle_no}.pdf`);
    });
  };

  if (!service) return null;

  const partsTotal = service.tasks?.reduce((acc, task) => (task.amount ? acc + task.amount : acc), 0) || 0;
  const laborTotal = service.extraTasks?.reduce((acc, extraTask) => (extraTask.extraAmount ? acc + extraTask.extraAmount : acc), 0) || 0;

  const hasTasks = service.tasks && service.tasks.some(task => task.task && task.unit && task.amount);
  const hasExtraTasks = service.extraTasks && service.extraTasks.some(extraTask => extraTask.extraTask && extraTask.extraAmount);

  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Invoice Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '25px' }}>
        <div ref={printRef} className="invoice-container">
          <div className="invoice-header">
            <Row className="align-items-center">
              <Col xs="auto">
                <img src={logo} alt="Logo" style={{ maxWidth: '100px', height: 'auto' }} />
              </Col>
              <Col>
                <h1 style={{ fontSize: '34px', color: 'black', marginLeft: '20px' }}>
                  NexGen AutoCare Invoice
                </h1>
              </Col>
            </Row>
          </div>
          <hr />
          <Row>
            <Col>
              <div className="invoice-details">
                <p>{service.customerName}</p>
                <p>{service.customerEmail}</p>
                <p>{service.customerContactNumber}</p>
              </div>
            </Col>
            <Col>
              <div className="invoice-details" style={{ textAlign: 'right' }}>
                <p>236/3, Pragathi Mawatha</p>
                <p>Katuwana Road Homagama</p>
                <p>Email: nextgenautocare354@gmail.com</p>
                <p>Contact: 0775411060</p>
              </div>
            </Col>
          </Row>
          <Table bordered>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>Invoice No</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.service_id}_{service.vehicle_no}</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>Vehicle No</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.vehicle_no}</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>Payment Type</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.paymentType}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>Date</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  {new Date().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>ODO Meter</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.odoMeter}</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee' }}>Vehicle Model</td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.vehicleModel}</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          {hasTasks && (
            <table className="invoice-table">
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: "450px" }}>Spare Part</th>
                  <th style={{ width: "100px" }}>Quantity</th>
                  <th style={{ width: "100px" }}>Rate (Rs.)</th>
                  <th>Amount (Rs.)</th>
                </tr>
              </thead>
              <tbody>
                {service.tasks
                  .filter(task => task.task && task.unit && task.amount)
                  .map((task, index) => (
                    <tr key={index}>
                      <td>{task.task}</td>
                      <td>{task.unit}</td>
                      <td style={{ textAlign: "right" }}>{(task.amount / task.unit).toFixed(2)}</td>
                      <td style={{ textAlign: "right" }}>{task.amount}.00</td>
                    </tr>
                  ))}
                {partsTotal > 0 && (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Parts Cost</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{partsTotal}.00</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {hasExtraTasks && (
            <table className="invoice-table">
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: "700px" }}>Labor</th>
                  <th>Amount (Rs.)</th>
                </tr>
              </thead>
              <tbody>
                {service.extraTasks
                  .filter(extraTask => extraTask.extraTask && extraTask.extraAmount)
                  .map((extraTask, index) => (
                    <tr key={index}>
                      <td>{extraTask.extraTask}</td>
                      <td style={{ textAlign: "right" }}>
                        {extraTask.extraAmount !== undefined ? `${extraTask.extraAmount}.00` : '0.00'}
                      </td>
                    </tr>
                  ))}
                {laborTotal > 0 && (
                  <tr>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Labor Cost</td>
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{laborTotal}.00</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {(partsTotal > 0 || laborTotal > 0) && (
            <table className="invoice-table">
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center', fontWeight: 'bold', width: "700px" }}>Total (Rs.)</td>
                  <td style={{ fontWeight: 'bold', textAlign: 'center' }}>{partsTotal + laborTotal}.00</td>
                </tr>
              </tbody>
            </table>
          )}
          <Row className="sign-section">
            <Col>
              <p>--------------------------------</p>
              <p>Company Signature</p>
            </Col>
            <Col>
              <p>--------------------------------</p>
              <p>Client Signature</p>
            </Col>
          </Row>
          <hr />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handlePrint}>
          <FaPrint /> Print
        </Button>
        <Button variant="success" onClick={handleDownload}>
          Download PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtraInvoice;