import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Col, Row, Container, Card, Form, InputGroup, Table } from 'react-bootstrap';
import './ViewCustomer.css';
import BASE_URL from '../config';

const ViewCustomer = () => {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [services, setServices] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [serviceSearch, setServiceSearch] = useState('');
  const [quotationSearch, setQuotationSearch] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await fetch(`${BASE_URL}/customers/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch customer');
        }
        const data = await response.json();
        setCustomer(data.data);
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await fetch(`${BASE_URL}/service`);
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        const customerServices = data.filter(service => service.customer_id === id);
        setServices(customerServices);
        setFilteredServices(customerServices); // Initialize filtered services
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    const fetchQuotations = async () => {
      try {
        const response = await fetch(`${BASE_URL}/quotation`);
        if (!response.ok) {
          throw new Error('Failed to fetch quotations');
        }
        const data = await response.json();
        const customerQuotations = data.filter(quotation => quotation.customer_id === id);
        setQuotations(customerQuotations);
        setFilteredQuotations(customerQuotations); // Initialize filtered quotations
      } catch (error) {
        console.error('Error fetching quotations:', error);
      }
    };

    fetchCustomer();
    fetchServices();
    fetchQuotations();
  }, [id]);

  useEffect(() => {
    // Filter services based on search query
    setFilteredServices(
      services.filter(service =>
        service.service_id.toLowerCase().includes(serviceSearch.toLowerCase()) ||
        service.vehicle_no.toLowerCase().includes(serviceSearch.toLowerCase())
      )
    );
  }, [serviceSearch, services]);

  useEffect(() => {
    // Filter quotations based on search query
    setFilteredQuotations(
      quotations.filter(quotation =>
        quotation.quotation_id.toLowerCase().includes(quotationSearch.toLowerCase()) ||
        quotation.vehicle_no.toLowerCase().includes(quotationSearch.toLowerCase())
      )
    );
  }, [quotationSearch, quotations]);

  return (
    <Container className="view-customer-container">
      <h2 className="page-title">Customer Details</h2>
      {customer ? (
        <Row>
          <Col md={8}>
            <Card className="customer-details">
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <hr />
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Customer ID</strong></td><td>{customer.customer_id}</td>
                    </tr>
                    <tr>
                      <td><strong>Title</strong></td>
                      <td>{customer.title}</td>
                    </tr>
                    <tr>
                      <td><strong>First Name</strong></td>
                      <td>{customer.firstName}</td>
                    </tr>
                    <tr>
                      <td><strong>Last Name</strong></td>
                      <td>{customer.lastName}</td>
                    </tr>
                    <tr>
                      <td><strong>Email</strong></td>
                      <td>{customer.email}</td>
                    </tr>
                    <tr>
                      <td><strong>NIC</strong></td>
                      <td>{customer.nic || '-'}</td>
                    </tr>
                    <tr>
                      <td><strong>Contact No</strong></td>
                      <td>{customer.contactNo || '-'}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="services-list">
              <Card.Body>
                <Card.Title>Services</Card.Title>
                <hr />
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search services..."
                    value={serviceSearch}
                    onChange={(e) => setServiceSearch(e.target.value)}
                  />
                </InputGroup>
                {filteredServices.length > 0 ? (
                  <div className="scrollable-list">
                    <ul>
                      {filteredServices.map(service => (
                        <li key={service.service_id}>
                          <Link to={`/view/${service.service_id}`}>
                            <p>{service.service_id} ({service.vehicle_no})</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>No services found for this customer.</p>
                )}
              </Card.Body>
            </Card>
            <br />
            <Card className="quotations-list">
              <Card.Body>
                <Card.Title>Quotations</Card.Title>
                <hr />
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Search quotations..."
                    value={quotationSearch}
                    onChange={(e) => setQuotationSearch(e.target.value)}
                  />
                </InputGroup>
                {filteredQuotations.length > 0 ? (
                  <div className="scrollable-list">
                    <ul>
                      {filteredQuotations.map(quotation => (
                        <li key={quotation.quotation_id}>
                          <Link to={`/view-quotation/${quotation.quotation_id}`}>
                            <p>{quotation.quotation_id} ({quotation.vehicle_no})</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>No quotations found for this customer.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <p className="loading-message">Loading...</p>
      )}
    </Container>
  );
};

export default ViewCustomer;