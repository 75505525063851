import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsers, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo512.png';
import '../features/Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <Nav className="flex-column">
        <Nav.Item>
          <Nav.Link as={NavLink} to="/dashboard" exact>
            <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/view-all-staff">
            <FontAwesomeIcon icon={faUsers} /> Staff Management
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/view-all-customers">
            <FontAwesomeIcon icon={faUser} /> Customer Management
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-services">
              <FontAwesomeIcon icon={faCog} /> Service Management
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={NavLink} to="/view-all-services">
                View All Services
              </Dropdown.Item>
              {/* <Dropdown.Item as={NavLink} to="/view-all-pending-services">
                View All Pending Services
              </Dropdown.Item> */}
              <Dropdown.Item as={NavLink} to="/view-all-invoices">
                View All Invoices
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/view-all-quotations">
            <FontAwesomeIcon icon={faUser} /> Quotation Management
          </Nav.Link>
        </Nav.Item>
        <Nav.Item></Nav.Item>
      </Nav>
    </div>
  );
};

export default Sidebar;